/* LAYOUT CSS */
.cover {
  background-color: $background-color;
  position: fixed;
  text-align: center;
  width: 100%;
  min-height: 400px;
  padding: 30px 15px 20px;
  z-index: 1;
  @include media-query($small-screen) {
    background-position: center;
    padding: 30px;
  }
  @include media-query($medium-screen) {
    height: 100vh;
    position: initial;
    width: 100%;
  }
}
.cover-card {
  color: $text-color;
  padding: 0 30px;
  .avatar {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    float: none;
  }
  .author_name {
    display: block;
    color: #F36170;
    font-size: 1.75em;
    text-transform: lowercase;
  }
  .author_job {
    display: block;
    color: $secondary;
    font-size: 0.8em;
    font-weight: 700;
    text-transform: uppercase;
    margin: -5px auto 10px;
  }
  .author_bio {
    font-size: 90%;
    display: block;
  }
  @include media-query($medium-screen) {
    text-align: left;
    margin: 0 auto;
    padding: 0 0px 80px;
  }
}
.social-links {
  margin-top: 0.5em;
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      display: inline-block;
      margin-left: 1em;
      a.social-link-item {
        color: $secondary;
        &:hover,
        &:focus {
          color: lighten($secondary, 14%);
        }
      }
    }
  }
}
.nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  .nav-item {
    display: inline-block;
    zoom: 1;
    & a {
      margin-top: 1em;
      text-transform: lowercase;
      font-size: 85%;
      color: $secondary;
      &:hover,
      &:focus {
        color: lighten($secondary, 14%);
      }
    }
  }
}

footer
{
	p
	{
		margin: 0;
		padding: 0;
		
		.powered-by
		{
			color: #ccc;
		}
	}
}

div.disqus_container
{
}

div#disqus_thread
{
	margin: 1em 10% 0;
	padding: 0 1em;
	border: 1px solid #ccc;
}

pre
{
	tab-size: 4;
}
