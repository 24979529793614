/* ==========================================================================
   Typography
   ========================================================================== */

//
// Global
//

html {
  line-height: $base-line-height;
  font-family: $font-family-base;
  font-weight: normal;
  color: $text-color;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: $font-size-base;
}

//
// Blockquote
//

blockquote {
  display: block;
	position: relative;
  padding-left: 20px;
  font-style: italic;
  border-left: 5px solid $secondary;
}

//
// Links
//

a {
   text-decoration: none;
   color: $primary;
   &:hover,
   &:focus {
     color: lighten($primary, 14%);
     text-decoration: none;
   }
}

//
// Header Styles
//

h1, h2, h3, h4, h5, h6 {
	font-weight: 600;
  line-height: 1.7;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  font-weight: inherit;
  color: $text-color;
  &:hover,
  &:focus {
    color: lighten($text-color, 24%);
  }
}
h1 { font-size: $font-size-h1; margin: calc($font-size-h1 / 2) 0 calc($font-size-h1 / 2.5) 0;}
h2 { font-size: $font-size-h2; margin: calc($font-size-h2 / 2) 0 calc($font-size-h2 / 2.5) 0;}
h3 { font-size: $font-size-h3; margin: calc($font-size-h3 / 2) 0 calc($font-size-h3 / 2.5) 0;}
h4 { font-size: $font-size-h4; margin: calc($font-size-h4 / 2) 0 calc($font-size-h4 / 2.5) 0;}
h5 { font-size: $font-size-h5; margin: calc($font-size-h5 / 2) 0 calc($font-size-h5 / 2.5) 0;}
h6 { font-size: $font-size-h6; margin: calc($font-size-h6 / 2) 0 calc($font-size-h6 / 2.5) 0; text-transform: uppercase;}

//
// Horizontal rules
//

hr {
  margin-top: $spacer-y;
  margin-bottom: $spacer-y;
  border: 0;
  border-top: $hr-border-width solid $hr-border-color;
}

//
// Text Styles
//

em { font-style: italic; }

strong { font-weight: 600; }

small,
.small {
  font-size: 80%;
  font-weight: normal;
}

p {
  margin: 1.2em 0;
  word-spacing: 0.05em;
  line-height: 1.6em;
}
