/**
  * Global setting
  */
*,
*:before,
*:after {
  @include box-sizing;
}
.notice {
  border: 1px solid lighten($text-color, 45%);
  border-radius: 0.4em;
  padding: 0.5em 1em;
}
img {
  height: auto;
  max-width: 100%;
}

// Tables
table,
th,
td {
  border: none;
}
.table {
  display: table;
}
.table-cell {
  display: table-cell;
}
.table-top {
  vertical-align: top;
}
.table-middle {
  vertical-align: middle;
}
.table-bottom {
  vertical-align: bottom;
}
table {
  width: 100%;
  display: table;
  &.bordered > thead > tr,
  &.bordered > tbody > tr {
    border-bottom: 1px solid $table-border-color;
  }
  &.striped > tbody {
    > tr:nth-child(odd) {
      background-color: $table-striped-color;
    }
    > tr > td {
      border-radius: 0;
    }
  }
  &.highlight > tbody > tr {
    @include transition(background-color .25s ease);
    &:hover {
      background-color: $table-striped-color;
    }
  }
  &.centered {
    thead tr th,
    tbody tr td,
    tfoot tr td {
      text-align: center;
    }
  }
}
thead {
  border-bottom: 1px solid $table-border-color;
}
td,
th {
  padding: 15px 5px;
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  border-radius: 2px;
}
tfoot {
  border-top: 1px solid $table-border-color;
}

// Global transition
b,
i,
strong,
em,
blockquote,
p,
q,
span,
figure,
img,
h1,
h2,
header,
input,
a {
  @include transition(all .1s ease);
}

// Footer
footer {
  text-align: center;
  padding: 3em 0;
  .heart-icon {
    color: $danger;
    text-shadow: 0 0 5px $white, 0 0 10px $danger;
  }
}
.related-articles {
  margin-top: 2em;
  h4 {
    font-family: $font-family-base;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
